module.exports = {
    is_enable: true,
    type: null,
    display_name: {
        type: 'translatable-text',
        meta: {
        default_lang: 'vi'
        },
        value: '',
        translations: [
        ]
    },
    icon_url: null,
    selected_default: false,
    payment_method_detail: {
        type: 'translatable-text',
        meta: {
        default_lang: 'vi'
        },
        value: '',
        translations: [
        ]
    },
}