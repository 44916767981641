<template>
    <div v-if="item" class="page-content">
        <page-breadcrumb title="Landing Page Detail" class="mb-2" :items="breadcrumbItems" />
        <h2><b>Native 1-1 - Payment Method</b></h2>
        <form-generator :model="item" :schema="item_language" />
        <div>
            <h4><b>Payment Method</b></h4>
            <b-card v-for="(method, index) in item.payment_methods" :key="index">
                <form-generator :model="method" :schema="item_form_schema" />
            </b-card>
        </div>
        <div>
            <b-button
                @click="addMethod"
                variant="success"
                class="btn-primary"
                size="md"
            >
                + Add payment method
            </b-button>
        </div>
        <action-buttons
            update-button
            back-button
            delete-button
            @update="update"
            :is-updating="isUpdating"
            @delete="deleteItem"
            :is-deleting="isDeleting"
        />
    </div>
</template>
<script>
import service from '../service';
import defaultObj from '../defaultObj';
const item_language = [
    {
    cols: 12,
    fields: [    
        { label: 'Market', field: 'language', input_type: 'language', disabled: true, 
            validate: { required: true }
        },
    ]
    }
]
const item_form_schema = [
  {
    cols: 12,
    fields: [
        {label: 'Enabled', field: 'is_enable', input_type:'switch'},
        { label: 'Payment Method Type', field: 'type', input_type: 'select', options: [
            {text:"Banking", value: 2},
            {text:"Momo", value: 14}
        ], validate: { required: true }},
        { label: 'Dislay Name', field: 'display_name', input_type: 'ui-component' },
        { label: 'Icon Url', field: 'icon_url'},
        { label: 'Selected Default', field: 'selected_default', input_type:'switch' },
        { label: 'Payment Method Details', field: 'payment_method_detail', input_type: 'ui-component' },
    ]
  }
];
export default {
    data(){
        return {
            item: null,
            item_form_schema,
            isUpdating: false,
            isDeleting: false,
            item_language,
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Native 1-1 Payment',
                    to: { name: 'native11-payment' },
                },
                {
                    text: `${this.item ? this.item.title : this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created() {
        this.getDetail()
    },
    methods: {
        async getDetail(){
            this.item = await service.getDetail({id: this.itemId});
        },
        async update() {
            this.isUpdating = true;
            await service.update({
                data: JSON.stringify(this.item),
            });
            this.getDetail();
            this.isUpdating = false;
        },
        async deleteItem() {
            await service.delete({ id: this.itemId });
            this.$router.push({ name: "native11-payment" });
        },
        addMethod(){
            this.item.payment_methods.push(JSON.parse(JSON.stringify(defaultObj)))
        }
    }
}
</script>